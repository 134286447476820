import { PlusOutlined, AlertFilled } from '@ant-design/icons'
import { Space, Modal, Table, Radio, Divider, Input, Select, Button } from 'antd'
import React, {useRef, useState} from 'react'
import { requestSuccess } from 'utils/requestHandler'

const AlertsModal = (props) => {

  const [comment, setComment] = useState('');
  const inputRef = useRef(null);
  console.log(props)

  const columns = [ 
    { 
      key: "ID", 
      title: "ID", 
      dataIndex: "ID", 
      sorter: true,
    }, 
    { 
      key: "URGENCY", 
      title: "Urgency", 
      render: (_, alert) => (
				alert.URGENCY==0?(<span style={{color:'green'}}>Information</span>):
				alert.URGENCY==1?(<span style={{color:'yellow'}}>Attention</span>):
				alert.URGENCY==2?(<span style={{color:'orange'}}>Escalated</span>):
				(<span style={{color:'red'}}>Urgent</span>)
			),
    }, 
    { 
      key: "DETAILS", 
      title: "Details", 
      dataIndex: "DETAILS"
    }, 
    { 
      key: "TIMESTAMP", 
      title: "Date", 
      render: (_, alert) => (
				<span>{new Date(alert.TIMESTAMP).toLocaleString()}</span>
			),
    },
    { 
      key: "ACCURACY", 
      title: "Accuracy", 
      render: (_, alert) => (
				<Radio.Group onChange={(val) => {
              props.setAlertAccuracy(alert.ID,val.target.value);
              if (alert.DETAILS === "FALL" && val.target.value === 0){
                requestSuccess("Please leave a comment for the false alarm...")
              }
            }} value={alert.ACCURACY}>
          <Radio value={1}>True</Radio>
          <Radio value={0}>False</Radio>
        </Radio.Group>
			),
    },
		{
			key: "COMMENT", 
      title: "Comment", 
      render: (_, alert) => (
				alert.COMMENT?alert.COMMENT:(alert.DETAILS == 'FALL' && alert.ACCURACY == false)?
				<Select
					style={{ width: 300 }}
					onChange={(value) => {
						props.setAlertAccuracyComment(alert.ID,value);
					}}
					placeholder="Please select..."
					dropdownRender={(menu) => (
						<>
							{menu}
							<Divider style={{ margin: '8px 0' }} />
							<Space style={{ padding: '0 8px 4px' }}>
								<Input
									placeholder="Please enter custom comment"
									ref={inputRef}
									value={comment}
                  onChange={(e) => setComment(e.target.value)}
									onKeyDown={(e) => e.stopPropagation()}
								/>
								<Button type="text" icon={<PlusOutlined />} onClick={(e)=>{
                  addItem(e,alert)
                  }}>
                  Add item
                </Button>
							</Space>
						</>
					)}
					options={props.alertCommentOptions.map((item) => ({ label: item, value: item }))}
				/>:""
			),
    }
	]

  const addItem = (e, curr) => {
    e.preventDefault();

		props.onChangeHOC('alertCommentOptions',[...props.alertCommentOptions, comment])
    const newAlerts = props.alerts.map((alert) =>
      alert.ID === curr.ID ? { ...alert, COMMENT: comment } : alert
    );
    props.onChangeHOC('alerts',newAlerts)
		props.setAlertAccuracyComment(curr.ID,comment);
    setComment('');
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  
  return (
    <Modal
      width={"auto"}
      title={
        <div style={{textAlign:'center'}}>
          <AlertFilled style={{color:'red'}}></AlertFilled> Alert
        </div>}
      open={true}
      onOk={()=>{
        props.action()
        props.close()
      }}
      onCancel={props.close}
    >
      <Table 
        dataSource={props.alerts} 
        columns={columns} >

      </Table>
    </Modal>
  )
}

export default AlertsModal